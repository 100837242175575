<script lang="ts" setup>
import LocationOverview from "@/components/location/LocationOverviewGrid.vue";
</script>

<template>
  <v-container fluid class="app-overview">
    <v-row>
      <v-col cols="auto">
        <header class="app-welcome-message">
          <h1>Overzicht van locaties</h1>
        </header>
      </v-col>
      <v-col cols="auto">
        <v-btn dark class="primary" :to="{ name: 'LocationAdd', params: { id: '0', reference: '0', addModus: true } }">
          <v-icon dark left>mdi-plus</v-icon>
          Locatie toevoegen
        </v-btn>
      </v-col>
      <v-col cols="auto">
        <v-btn dark class="primary" :to="{ name: 'LocationAddWizard', params: { id: '0', reference: '0', addModus: true } }">
          <v-icon dark left>mdi-plus</v-icon>
          Wizard locatie toevoegen
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="auto">
        <header class="app-welcome-message">
          <p>In dit overzicht worden alle locaties getoond. Aan de hand van het filter kunt u uw gewenste inzicht creëren.</p>
        </header>
      </v-col>
    </v-row>
    <v-row class="overview-wrapper">
      <v-col cols="12">
        <location-overview :allowMutationAddress="false" :explicitPageSize="25"></location-overview>
      </v-col>
    </v-row>
  </v-container>
</template>
