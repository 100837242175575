var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "information-popup", class: { active: _setup.toggled } },
    [
      _c("span", {
        staticClass: "icon-information-icon",
        on: { click: _setup.toggle },
      }),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _setup.toggled === true,
              expression: "toggled === true",
            },
          ],
          staticClass: "information-dropdown",
        },
        [
          _c(
            "div",
            { staticClass: "information-dropdown-header" },
            [
              _vm._t("header"),
              _c("a", {
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _setup.toggle.apply(null, arguments)
                  },
                },
              }),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "information-dropdown-content" },
            [_vm._t("default")],
            2
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }