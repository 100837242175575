<script lang="ts" setup>
import PartyOverview from "@/components/party/PartyOverviewGrid.vue";
</script>

<template>
  <v-container fluid class="app-overview">
    <v-row>
      <v-col cols="auto">
        <header class="app-welcome-message">
          <h1>Overzicht van partijen</h1>
        </header>
      </v-col>
      <v-col cols="auto">
        <v-btn dark class="primary" :to="{ name: 'PartyAdd', params: { id: '0', reference: '0', addModus: true } }">
          <v-icon dark left>mdi-plus</v-icon>
          Partij toevoegen
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="auto">
        <header class="app-welcome-message">
          <p>In dit overzicht worden alle parties getoond. Aan de hand van het filter kunt u uw gewenste inzicht creëren.</p>
        </header>
      </v-col>
    </v-row>
    <v-row class="overview-wrapper">
      <v-col cols="12">
        <party-overview></party-overview>
      </v-col>
    </v-row>
  </v-container>
</template>
