var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-layout",
    [
      _c(
        "v-app-bar",
        { staticClass: "navbar", attrs: { app: "", color: "white" } },
        [
          _c("v-app-bar-nav-icon", {
            staticClass: "d-flex d-md-none",
            on: {
              click: function ($event) {
                _setup.drawerLeft = true
              },
            },
          }),
          _c(
            "div",
            { staticClass: "d-flex align-center" },
            [
              _c(
                "router-link",
                { attrs: { to: { name: "Dashboard" } } },
                [
                  _c("v-img", {
                    staticClass: "shrink mr-2",
                    attrs: {
                      alt: "INNAX Logo",
                      contain: "",
                      src: require("@/assets/img/logo.png"),
                      width: "140",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-layout",
            { staticClass: "d-none d-md-flex" },
            [
              _c("v-btn", { attrs: { text: "", to: { name: "Dashboard" } } }, [
                _vm._v("DataHub"),
              ]),
              _c(
                "v-menu",
                {
                  attrs: { "offset-y": "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on, attrs, value }) {
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                { attrs: { text: "" } },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _vm._v("Pandenlijst "),
                              value
                                ? _c("v-icon", { attrs: { right: "" } }, [
                                    _vm._v("mdi-chevron-up"),
                                  ])
                                : _c("v-icon", { attrs: { right: "" } }, [
                                    _vm._v("mdi-chevron-down"),
                                  ]),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c(
                    "v-list",
                    { staticClass: "navbar" },
                    [
                      _c(
                        "v-list-item",
                        { attrs: { to: { name: "Locations" } } },
                        [
                          _c(
                            "v-list-item-icon",
                            [_c("v-icon", [_vm._v("mdi-crosshairs")])],
                            1
                          ),
                          _c("v-list-item-title", [_vm._v("Locaties")]),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        { attrs: { to: { name: "Parties" } } },
                        [
                          _c(
                            "v-list-item-icon",
                            [_c("v-icon", [_vm._v("mdi-domain")])],
                            1
                          ),
                          _c("v-list-item-title", [_vm._v("Partijen")]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-menu",
                {
                  attrs: { "offset-y": "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on, attrs, value }) {
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                { attrs: { text: "" } },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _vm._v("Maatregelen "),
                              value
                                ? _c("v-icon", { attrs: { right: "" } }, [
                                    _vm._v("mdi-chevron-up"),
                                  ])
                                : _c("v-icon", { attrs: { right: "" } }, [
                                    _vm._v("mdi-chevron-down"),
                                  ]),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c(
                    "v-list",
                    { staticClass: "navbar" },
                    [
                      _c(
                        "v-list-item",
                        { attrs: { to: { name: "Measures" } } },
                        [
                          _c(
                            "v-list-item-icon",
                            [
                              _c("v-icon", [
                                _vm._v("mdi-language-markdown-outline"),
                              ]),
                            ],
                            1
                          ),
                          _c("v-list-item-title", [_vm._v("Definities")]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _setup.allowEnergyUsage
                ? _c(
                    "v-menu",
                    {
                      attrs: { "offset-y": "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on, attrs, value }) {
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      { attrs: { text: "" } },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _vm._v("Energiebeheer "),
                                    value
                                      ? _c("v-icon", { attrs: { right: "" } }, [
                                          _vm._v("mdi-chevron-up"),
                                        ])
                                      : _c("v-icon", { attrs: { right: "" } }, [
                                          _vm._v("mdi-chevron-down"),
                                        ]),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        3524869056
                      ),
                    },
                    [
                      _c(
                        "v-list",
                        { staticClass: "navbar" },
                        [
                          _c(
                            "v-list-item",
                            {
                              attrs: { text: "", to: { name: "EU-Registers" } },
                            },
                            [
                              _c(
                                "v-list-item-icon",
                                [_c("v-icon", [_vm._v("mdi-speedometer")])],
                                1
                              ),
                              _c("v-list-item-title", [_vm._v("Meters")]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-menu",
                {
                  attrs: { "offset-y": "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on, attrs, value }) {
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                { attrs: { text: "" } },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _vm._v("Identiteits- en toegangsbeheer "),
                              value
                                ? _c("v-icon", { attrs: { right: "" } }, [
                                    _vm._v("mdi-chevron-up"),
                                  ])
                                : _c("v-icon", { attrs: { right: "" } }, [
                                    _vm._v("mdi-chevron-down"),
                                  ]),
                            ],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c(
                    "v-list",
                    { staticClass: "navbar" },
                    [
                      _c(
                        "v-list-item-group",
                        [
                          _c(
                            "v-list-item",
                            { attrs: { to: { name: "IAM-UsersOverview" } } },
                            [
                              _c(
                                "v-list-item-icon",
                                [
                                  _c("v-icon", [
                                    _vm._v("mdi-account-multiple"),
                                  ]),
                                ],
                                1
                              ),
                              _c("v-list-item-title", [_vm._v("Gebruikers")]),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            {
                              attrs: { to: { name: "IAM-UserGroupsOverview" } },
                            },
                            [
                              _c(
                                "v-list-item-icon",
                                [
                                  _c("v-icon", [
                                    _vm._v(
                                      _vm._s(_setup.icons.mdiAccountGroup)
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c("v-list-item-title", [_vm._v("Groepen")]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _c(
                        "v-list-item-group",
                        [
                          _c(
                            "v-list-item",
                            {
                              attrs: { to: { name: "IAM-ResourcesOverview" } },
                            },
                            [
                              _c(
                                "v-list-item-icon",
                                [_c("v-icon", [_vm._v("mdi-apps")])],
                                1
                              ),
                              _c("v-list-item-title", [_vm._v("Resources")]),
                            ],
                            1
                          ),
                          _c(
                            "v-list-item",
                            { attrs: { to: { name: "IAM-ProjectsOverview" } } },
                            [
                              _c(
                                "v-list-item-icon",
                                [
                                  _c("v-icon", [
                                    _vm._v(
                                      _vm._s(
                                        _setup.icons
                                          .mdiClipboardTextMultipleOutline
                                      ) + " "
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c("v-list-item-title", [_vm._v("Projecten")]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _c(
                        "v-list-item-group",
                        [
                          _c(
                            "v-list-item",
                            { attrs: { to: { name: "IAM-RolesOverview" } } },
                            [
                              _c(
                                "v-list-item-icon",
                                [
                                  _c("v-icon", [
                                    _vm._v(
                                      _vm._s(_setup.icons.mdiScriptTextOutline)
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c("v-list-item-title", [_vm._v("Rollen")]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _setup.userIsAdmin
                ? _c(
                    "v-menu",
                    {
                      attrs: { "offset-y": "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on, attrs, value }) {
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      { attrs: { text: "" } },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _vm._v("Beheer "),
                                    value
                                      ? _c("v-icon", { attrs: { right: "" } }, [
                                          _vm._v("mdi-chevron-up"),
                                        ])
                                      : _c("v-icon", { attrs: { right: "" } }, [
                                          _vm._v("mdi-chevron-down"),
                                        ]),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        735360119
                      ),
                    },
                    [
                      _c(
                        "v-list",
                        { staticClass: "navbar" },
                        [
                          _c(
                            "v-list-item",
                            { attrs: { to: { name: "MD-Dashboard" } } },
                            [
                              _c(
                                "v-list-item-icon",
                                [
                                  _c("v-icon", [
                                    _vm._v("mdi-format-list-bulleted"),
                                  ]),
                                ],
                                1
                              ),
                              _c("v-list-item-title", [_vm._v("Master data")]),
                            ],
                            1
                          ),
                          _setup.allowImportExcel
                            ? _c(
                                "v-list-item",
                                { attrs: { to: { name: "ImportData" } } },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [_c("v-icon", [_vm._v("mdi-import")])],
                                    1
                                  ),
                                  _c("v-list-item-title", [
                                    _vm._v("Importeren"),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                          _setup.allowImportVabi
                            ? _c(
                                "v-list-item",
                                { attrs: { to: { name: "ImportVabiData" } } },
                                [
                                  _c(
                                    "v-list-item-icon",
                                    [
                                      _c("v-img", {
                                        attrs: {
                                          contain: "",
                                          src: require("@/assets/img/logo-vabi.png"),
                                          alt: "VABI",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("v-list-item-title", [
                                    _vm._v("Vabi woningen importeren"),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c("v-divider"),
                          _c(
                            "v-list-item",
                            { attrs: { to: { name: "Info" } } },
                            [
                              _c(
                                "v-list-item-icon",
                                [
                                  _c("v-icon", [
                                    _vm._v("mdi-information-outline"),
                                  ]),
                                ],
                                1
                              ),
                              _c("v-list-item-title", [_vm._v("Info")]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c("v-spacer"),
          _c(
            "v-layout",
            { staticClass: "d-none d-md-flex", attrs: { "justify-end": "" } },
            [
              _c(
                "v-menu",
                {
                  attrs: { "offset-y": "", origin: "top right" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on, attrs }) {
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                { attrs: { text: "" } },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_setup.userStore.getUserFullname) +
                                  " "
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [
                  _c(
                    "v-list",
                    { staticClass: "navbar" },
                    [
                      _c(
                        "v-list-item",
                        {
                          attrs: { link: "" },
                          on: {
                            click: function ($event) {
                              return _setup.logout()
                            },
                          },
                        },
                        [
                          _c(
                            "v-list-item-icon",
                            [_c("v-icon", [_vm._v("mdi-logout")])],
                            1
                          ),
                          _c("v-list-item-title", [_vm._v("Afmelden")]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-app-bar-nav-icon",
            {
              staticClass: "d-flex d-md-none",
              on: {
                click: function ($event) {
                  _setup.drawerRight = true
                },
              },
            },
            [_c("v-icon", [_vm._v("mdi-account-outline")])],
            1
          ),
        ],
        1
      ),
      _c(
        "v-navigation-drawer",
        {
          attrs: { absolute: "", temporary: "" },
          model: {
            value: _setup.drawerLeft,
            callback: function ($$v) {
              _setup.drawerLeft = $$v
            },
            expression: "drawerLeft",
          },
        },
        [
          _c(
            "v-list",
            { attrs: { nav: "" } },
            [
              _c(
                "v-list-item",
                { attrs: { to: { name: "Dashboard" } } },
                [_c("v-list-item-title", [_vm._v("DataHub")])],
                1
              ),
              _c("v-divider"),
              _c(
                "v-list-group",
                {
                  attrs: { value: false },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function () {
                        return [
                          _c("v-list-item-title", [_vm._v("Pandenlijst")]),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c(
                    "v-list-item",
                    { attrs: { to: { name: "Locations" } } },
                    [_c("v-list-item-title", [_vm._v("Locaties")])],
                    1
                  ),
                  _c(
                    "v-list-item",
                    { attrs: { to: { name: "Parties" } } },
                    [_c("v-list-item-title", [_vm._v("Partijen")])],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-list-group",
                {
                  attrs: { value: false },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function () {
                        return [
                          _c("v-list-item-title", [_vm._v("Maatregelen")]),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c(
                    "v-list-item",
                    { attrs: { to: { name: "Measures" } } },
                    [_c("v-list-item-title", [_vm._v("Definities")])],
                    1
                  ),
                ],
                1
              ),
              _c("v-divider"),
              _setup.allowEnergyUsage
                ? _c(
                    "v-list-group",
                    {
                      attrs: { value: false },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function () {
                              return [
                                _c("v-list-item-title", [
                                  _vm._v("Energiebeheer"),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        2597691213
                      ),
                    },
                    [
                      _c(
                        "v-list-item",
                        { attrs: { to: { name: "EU-Registers" } } },
                        [_c("v-list-item-title", [_vm._v("Meters")])],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c("v-divider"),
              _c(
                "v-list-group",
                {
                  attrs: { value: false },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function () {
                        return [_c("v-list-item-title", [_vm._v("Beheer")])]
                      },
                      proxy: true,
                    },
                  ]),
                },
                [
                  _c(
                    "v-list-item",
                    { attrs: { to: { name: "MD-Dashboard" } } },
                    [_c("v-list-item-title", [_vm._v("Master data")])],
                    1
                  ),
                  _setup.allowImportExcel
                    ? _c(
                        "v-list-item",
                        { attrs: { to: { name: "ImportData" } } },
                        [_c("v-list-item-title", [_vm._v("Importeren")])],
                        1
                      )
                    : _vm._e(),
                  _setup.allowImportVabi
                    ? _c(
                        "v-list-item",
                        { attrs: { to: { name: "ImportVabiData" } } },
                        [
                          _c("v-list-item-title", [
                            _vm._v("Vabi woningen importeren"),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-list-item",
                    { attrs: { to: { name: "Info" } } },
                    [_c("v-list-item-title", [_vm._v("Info")])],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-navigation-drawer",
        {
          attrs: { absolute: "", temporary: "", right: "" },
          model: {
            value: _setup.drawerRight,
            callback: function ($$v) {
              _setup.drawerRight = $$v
            },
            expression: "drawerRight",
          },
        },
        [
          _c(
            "v-list",
            { attrs: { nav: "" } },
            [
              _c("v-list-item-title", { staticClass: "ma-2" }, [
                _vm._v(" " + _vm._s(_setup.userStore.getUserFullname) + " "),
              ]),
              _c("v-divider"),
              _c(
                "v-list-item",
                {
                  attrs: { link: "" },
                  on: {
                    click: function ($event) {
                      return _setup.logout()
                    },
                  },
                },
                [
                  _c(
                    "v-list-item-icon",
                    [_c("v-icon", [_vm._v("mdi-logout")])],
                    1
                  ),
                  _c("v-list-item-title", [_vm._v("Afmelden")]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-main", [_c("router-view")], 1),
      _c(
        "v-footer",
        { attrs: { app: "", fixed: "" } },
        [
          _c(
            "v-container",
            { attrs: { fluid: "", "pa-0": "" } },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "pa-1", attrs: { align: "center" } },
                    [
                      _vm._v(
                        " © " +
                          _vm._s(new Date().getFullYear()) +
                          " - Consolidated Location List "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }