var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "2" } }, [
            _c(
              "header",
              { staticClass: "app-welcome-message" },
              [_vm._t("header")],
              2
            ),
          ]),
          _vm.allowMutation && _vm.addLink
            ? _c(
                "v-col",
                { attrs: { cols: "1" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "primary",
                      attrs: { dark: "", to: _vm.addLink },
                    },
                    [
                      _c("v-icon", { attrs: { dark: "", center: "" } }, [
                        _vm._v("mdi-plus"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c("v-data-table", {
        attrs: {
          dense: "",
          options: _setup.gridOptions,
          headers: _setup.headers,
          "hide-default-footer": "",
          items: _setup.gridData,
          loading: _setup.gridIsLoading,
          "server-items-length": _setup.totalItems,
          "items-per-page": _setup.pageSize,
        },
        on: {
          "update:options": [
            function ($event) {
              _setup.gridOptions = $event
            },
            _setup.changedOptions,
          ],
        },
        scopedSlots: _vm._u(
          [
            {
              key: `item.datFrom`,
              fn: function ({ item }) {
                return [
                  _vm._v(
                    " " + _vm._s(_setup.formatDateUtc(item.datFrom)) + " "
                  ),
                ]
              },
            },
            {
              key: `item.datTill`,
              fn: function ({ item }) {
                return [
                  _vm._v(
                    " " + _vm._s(_setup.formatDateUtc(item.datTill)) + " "
                  ),
                ]
              },
            },
            {
              key: `item.locRef`,
              fn: function ({ item }) {
                return [
                  _vm._v(" " + _vm._s(item.locRef) + " "),
                  _vm.showChilds
                    ? _c(
                        "v-btn",
                        {
                          attrs: {
                            to: {
                              name: "Location",
                              params: {
                                locationReference: item.locRef.toString(),
                              },
                            },
                            icon: "",
                            small: "",
                            plain: "",
                          },
                        },
                        [
                          _c("v-icon", { attrs: { small: "" } }, [
                            _vm._v(" mdi-link-variant "),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              },
            },
            _vm.allowMutation
              ? {
                  key: `item.actions`,
                  fn: function ({ item }) {
                    return [
                      _vm.showChilds
                        ? _c(
                            "v-btn",
                            {
                              attrs: {
                                to: {
                                  name: "SublocationEdit",
                                  params: {
                                    locationReference: _vm.locationReference,
                                    id: item.locationParentId,
                                    addModus: false,
                                  },
                                },
                                icon: "",
                                small: "",
                                plain: "",
                              },
                            },
                            [
                              _c("v-icon", { attrs: { small: "" } }, [
                                _vm._v(" mdi-pencil "),
                              ]),
                            ],
                            1
                          )
                        : _c(
                            "v-btn",
                            {
                              attrs: {
                                to: {
                                  name: "AddressDetails",
                                  params: {
                                    locationReference:
                                      _vm.locationReference.toString(),
                                    id: item.masterId,
                                    addModus: false,
                                  },
                                },
                                icon: "",
                                small: "",
                                plain: "",
                              },
                            },
                            [
                              _c("v-icon", { attrs: { small: "" } }, [
                                _vm._v(" mdi-pencil "),
                              ]),
                            ],
                            1
                          ),
                    ]
                  },
                }
              : null,
          ],
          null,
          true
        ),
      }),
      _vm.showChilds
        ? _c(_setup.GridPagination, {
            attrs: {
              pageNumber: _setup.pageNumber,
              pageSize: _setup.pageSize,
              pageCount: _setup.pageCount,
            },
            on: { changedPaging: _setup.changedPaging },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }