var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_setup.props.editMode,
                  expression: "!props.editMode",
                },
              ],
              staticClass: "form-group",
              attrs: { cols: "auto" },
            },
            [
              _c(
                "v-btn",
                {
                  staticClass: "primary",
                  on: {
                    click: [
                      function ($event) {
                        $event.stopPropagation()
                      },
                      function ($event) {
                        return _setup.emits("edit-tl")
                      },
                    ],
                  },
                },
                [
                  _c("v-icon", { attrs: { dark: "", left: "" } }, [
                    _vm._v("mdi-pencil-circle"),
                  ]),
                  _vm._v(" Bewerk geldigheid "),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_setup.props.editMode,
                  expression: "!props.editMode",
                },
              ],
              staticClass: "form-group",
              attrs: { cols: "auto" },
            },
            [
              _c(
                "v-btn",
                {
                  staticClass: "primary",
                  on: {
                    click: [
                      function ($event) {
                        $event.stopPropagation()
                      },
                      function ($event) {
                        return _setup.emits("edit")
                      },
                    ],
                  },
                },
                [
                  _c("v-icon", { attrs: { dark: "", left: "" } }, [
                    _vm._v("mdi-pencil-circle"),
                  ]),
                  _vm._v(" Bewerk data "),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_setup.props.editMode,
                  expression: "!props.editMode",
                },
              ],
              staticClass: "form-group",
              attrs: { cols: "auto" },
            },
            [
              _c(
                "v-btn",
                {
                  staticClass: "primary",
                  on: {
                    click: [
                      function ($event) {
                        $event.stopPropagation()
                      },
                      function ($event) {
                        return _setup.emits("add-new-detail")
                      },
                    ],
                  },
                },
                [
                  _c("v-icon", { attrs: { dark: "", left: "" } }, [
                    _vm._v("mdi-plus"),
                  ]),
                  _vm._v(" Versie toevoegen "),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _setup.props.editMode,
                  expression: "props.editMode",
                },
              ],
              staticClass: "form-group",
              attrs: { cols: "auto" },
            },
            [
              _c(
                "v-btn",
                {
                  staticClass: "primary",
                  attrs: { disabled: !_setup.props.allowSave },
                  on: {
                    click: function ($event) {
                      return _setup.onSave()
                    },
                  },
                },
                [
                  _c("v-icon", { attrs: { dark: "", left: "" } }, [
                    _vm._v("mdi-checkbox-marked-circle"),
                  ]),
                  _vm._v(" Opslaan "),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _setup.props.editMode,
                  expression: "props.editMode",
                },
              ],
              staticClass: "form-group",
              attrs: { cols: "auto" },
            },
            [
              _c(
                "v-btn",
                {
                  on: {
                    click: function ($event) {
                      return _setup.onCancel()
                    },
                  },
                },
                [
                  _c("v-icon", { attrs: { dark: "", left: "" } }, [
                    _vm._v("mdi-cancel"),
                  ]),
                  _vm._v(" Annuleren "),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-spacer"),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }