var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-row",
        { staticClass: "overview-filters align-items-start" },
        [
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              _c("filter-set", {
                attrs: {
                  name: _setup.FilterDataName.Party,
                  filters: _setup.filters,
                },
                on: {
                  "filter-search": _setup.handleFilterSearch,
                  "filters-changed27": _setup.handleFiltersChanged,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-data-table", {
        attrs: {
          dense: "",
          options: _setup.gridOptions,
          headers: _setup.headers,
          "hide-default-footer": "",
          items: _setup.gridData,
          loading: _setup.gridIsLoading,
          "items-per-page": _setup.pageSize,
          "server-items-length": _setup.totalItems,
        },
        on: {
          "update:options": [
            function ($event) {
              _setup.gridOptions = $event
            },
            _setup.changedOptions,
          ],
        },
        scopedSlots: _vm._u(
          [
            {
              key: `item.dateFrom`,
              fn: function ({ item }) {
                return [
                  _vm._v(
                    " " + _vm._s(_setup.formatDateUtc(item.dateFrom)) + " "
                  ),
                ]
              },
            },
            {
              key: `item.dateTill`,
              fn: function ({ item }) {
                return [
                  _vm._v(
                    " " + _vm._s(_setup.formatDateUtc(item.dateTill)) + " "
                  ),
                ]
              },
            },
            {
              key: `item.typeKey`,
              fn: function ({ item }) {
                return [
                  item.typeKey == "EnergyLabels"
                    ? _c("v-icon", [_vm._v("mdi-label-outline")])
                    : item.typeKey == "Homes"
                    ? _c("v-icon", [_vm._v("mdi-home-outline")])
                    : item.typeKey == "Buildings"
                    ? _c("v-icon", [_vm._v("mdi-office-building-outline")])
                    : _vm._e(),
                ]
              },
            },
            {
              key: `item.actions`,
              fn: function ({ item }) {
                return [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        to: {
                          name: "Party",
                          params: {
                            partyReference: item.reference.toString(),
                            addModus: false,
                          },
                        },
                        icon: "",
                        small: "",
                        plain: "",
                      },
                    },
                    [
                      _c("v-icon", { attrs: { small: "" } }, [
                        _vm._v(" mdi-table "),
                      ]),
                    ],
                    1
                  ),
                ]
              },
            },
          ],
          null,
          true
        ),
      }),
      _c(_setup.GridPagination, {
        attrs: {
          pageNumber: _setup.pageNumber,
          pageSize: _setup.pageSize,
          pageCount: _setup.pageCount,
        },
        on: { changedPaging: _setup.changedPaging },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }