var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    {
      staticClass: "overview-loader",
      style: _setup.loaderStyles,
      attrs: { "fill-height": "" },
    },
    [
      _c(
        "v-row",
        [
          _c("v-col", [
            _c("span", {
              staticClass: "icon-loader-icon",
              class: { spinner: _setup.props.isLoading },
            }),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }