var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-row",
        { staticClass: "overview-filters align-items-start" },
        [
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              _c("filter-set", {
                attrs: {
                  name: _setup.FilterDataName.Location,
                  filters: _setup.filters,
                },
                on: {
                  "filter-search": _setup.handleFilterSearch,
                  "filters-changed27": _setup.handleFiltersChanged,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-data-table", {
        attrs: {
          dense: "",
          options: _setup.gridOptions,
          headers: _setup.headers,
          items: _setup.gridData,
          "hide-default-footer": "",
          loading: _setup.gridIsLoading,
          "items-per-page": _setup.pageSize,
          "server-items-length": _setup.totalItems,
          "show-expand": "",
          "single-expand": _setup.singleExpand,
          expanded: _setup.expanded,
        },
        on: {
          "update:options": [
            function ($event) {
              _setup.gridOptions = $event
            },
            _setup.changedOptions,
          ],
          "update:expanded": function ($event) {
            _setup.expanded = $event
          },
        },
        scopedSlots: _vm._u(
          [
            {
              key: `item.dateFrom`,
              fn: function ({ item }) {
                return [
                  _vm._v(
                    " " + _vm._s(_setup.formatDateUtc(item.dateFrom)) + " "
                  ),
                ]
              },
            },
            {
              key: `item.dateTill`,
              fn: function ({ item }) {
                return [
                  _vm._v(
                    " " + _vm._s(_setup.formatDateUtc(item.dateTill)) + " "
                  ),
                ]
              },
            },
            {
              key: `item.parReference`,
              fn: function ({ item }) {
                return [
                  item.hasMultipleParents
                    ? _c("span", [_vm._v("meerdere locaties")])
                    : item.parReference
                    ? _c("span", [
                        _vm._v(
                          _vm._s(item.parReference) +
                            ": " +
                            _vm._s(item.parDescr)
                        ),
                      ])
                    : _vm._e(),
                ]
              },
            },
            {
              key: `item.actions`,
              fn: function ({ item }) {
                return [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        to: {
                          name: "Location",
                          params: {
                            locationReference: item.reference.toString(),
                          },
                        },
                        icon: "",
                        small: "",
                        plain: "",
                      },
                    },
                    [
                      _c("v-icon", { attrs: { small: "" } }, [
                        _vm._v(" mdi-table "),
                      ]),
                    ],
                    1
                  ),
                ]
              },
            },
            {
              key: "top",
              fn: function () {
                return [
                  _c("v-switch", {
                    staticClass: "mt-1",
                    attrs: { label: "Een adres tegelijk uitklappen" },
                    model: {
                      value: _setup.singleExpand,
                      callback: function ($$v) {
                        _setup.singleExpand = $$v
                      },
                      expression: "singleExpand",
                    },
                  }),
                ]
              },
              proxy: true,
            },
            {
              key: "expanded-item",
              fn: function ({ headers, item }) {
                return [
                  _c(
                    "td",
                    { attrs: { colspan: headers.length } },
                    [
                      _c(_setup.LocationAddress, {
                        staticClass: "mt-3",
                        attrs: {
                          colspan: headers.length,
                          allowMutation: _vm.allowMutationAddress,
                          partyReference: _vm.partyReference,
                          locationReference: item.reference.toString(),
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "header",
                              fn: function () {
                                return [_c("h4", [_vm._v("Adressen")])]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                      _c("br"),
                    ],
                    1
                  ),
                ]
              },
            },
          ],
          null,
          true
        ),
      }),
      _c(_setup.GridPagination, {
        attrs: {
          pageNumber: _setup.pageNumber,
          pageSize: _setup.pageSize,
          pageCount: _setup.pageCount,
        },
        on: { changedPaging: _setup.changedPaging },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }