var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { staticClass: "app-overview", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "auto" } }, [
            _c("header", { staticClass: "app-welcome-message" }, [
              _c("h1", [_vm._v("Overzicht van partijen")]),
            ]),
          ]),
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "primary",
                  attrs: {
                    dark: "",
                    to: {
                      name: "PartyAdd",
                      params: { id: "0", reference: "0", addModus: true },
                    },
                  },
                },
                [
                  _c("v-icon", { attrs: { dark: "", left: "" } }, [
                    _vm._v("mdi-plus"),
                  ]),
                  _vm._v(" Partij toevoegen "),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { cols: "auto" } }, [
            _c("header", { staticClass: "app-welcome-message" }, [
              _c("p", [
                _vm._v(
                  "In dit overzicht worden alle parties getoond. Aan de hand van het filter kunt u uw gewenste inzicht creëren."
                ),
              ]),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "overview-wrapper" },
        [_c("v-col", { attrs: { cols: "12" } }, [_c(_setup.PartyOverview)], 1)],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }