var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-layout",
    { staticClass: "login", attrs: { "flex-column": "" } },
    [
      _c("header", [
        _c("h1", { staticClass: "large" }, [
          _vm._v("Welkom bij de pandenlijst"),
        ]),
      ]),
      _c("footer", [
        _c(
          "button",
          { staticClass: "btn-primary", on: { click: _setup.login } },
          [_vm._v("Inloggen")]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }