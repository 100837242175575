<script lang="ts" setup>
import MeasureOverview from "@/components/measure/MeasureOverviewGrid.vue";
</script>

<template>
  <v-container fluid class="app-overview">
    <v-row>
      <v-col cols="auto">
        <header class="app-welcome-message">
          <h1>Overzicht van maatregel definities</h1>
        </header>
      </v-col>
      <v-col cols="auto">
        <v-btn dark class="primary" :to="{ name: 'MeasureDetailsAdd', params: { id: '', addModus: true } }">
          <v-icon dark left>mdi-plus</v-icon>
          Maatregel toevoegen
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="auto">
        <header class="app-welcome-message">
          <p>In dit overzicht worden alle maatregelen getoond. Aan de hand van het filter kunt u uw gewenste inzicht creëren.</p>
        </header>
      </v-col>
    </v-row>
    <v-row class="overview-wrapper">
      <v-col cols="12">
        <measure-overview :allowMutationAddress="false" :explicitPageSize="25"></measure-overview>
      </v-col>
    </v-row>
  </v-container>
</template>
