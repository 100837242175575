var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("v-main", [
    _c(
      "div",
      { staticClass: "account-page" },
      [
        _c(
          "div",
          { staticClass: "welcome" },
          [
            _c(
              "div",
              { staticClass: "logo" },
              [
                _c("v-img", {
                  staticClass: "image",
                  attrs: {
                    alt: "INNAX Logo",
                    src: require("@/assets/img/logo.png"),
                  },
                }),
              ],
              1
            ),
            _c(_setup.AccountLogin),
          ],
          1
        ),
        _c("v-layout", { staticClass: "main-image" }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }