var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-layout",
    { staticClass: "login", attrs: { "flex-column": "" } },
    [
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { md: "2", sm: "4" } }),
          _c("v-col", { attrs: { md: "3", sm: "6" } }, [
            _c("br"),
            _c("br"),
            _c("br"),
            _c(
              "div",
              { staticClass: "logo" },
              [
                _c("v-img", {
                  staticClass: "image",
                  attrs: {
                    alt: "INNAX Logo",
                    src: require("@/assets/img/logo.png"),
                  },
                }),
              ],
              1
            ),
            _c("br"),
            _c("br"),
            _c("br"),
          ]),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { md: "2", sm: "4" } }),
          _c("v-col", { attrs: { md: "6", sm: "12" } }, [
            _c("h1", [
              _vm._v("U bent niet (meer) geauthoriseerd voor de pandenlijst"),
            ]),
          ]),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { md: "2", sm: "4" } }),
          _c("v-col", { attrs: { md: "6", sm: "12" } }, [
            _c("h2", [_vm._v("Neem contact op met de administrator")]),
          ]),
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-col", { attrs: { md: "2", sm: "4" } }),
          _c("v-col", { attrs: { md: "6", sm: "12" } }, [
            _c(
              "button",
              { staticClass: "btn-primary", on: { click: _setup.logout } },
              [_vm._v("Afmelden")]
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }