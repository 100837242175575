<script lang="ts" setup>
import AccountLogin from "@/components/account/AccountLogin.vue";
</script>

<template>
  <v-main>
    <div class="account-page">
      <div class="welcome">
        <div class="logo">
          <v-img alt="INNAX Logo" src="@/assets/img/logo.png" class="image" />
        </div>
        <account-login></account-login>
      </div>
      <v-layout class="main-image"> </v-layout>
    </div>
  </v-main>
</template>
