<script lang="ts" setup>
const props = defineProps({
  id: {
    type: String,
    default: "information-modal",
  },
  buttonType: {
    type: String,
    default: "info",
  },
});
</script>

<template>
  <div class="information-modal" :class="{ 'has-button': props.buttonType === 'text' }">
    <a href="#" type="button" data-toggle="modal" :data-target="'#' + props.id" v-if="props.buttonType === 'info'">
      <span class="icon-information-icon"></span>
    </a>

    <a href="#" class="widget-link" type="button" data-toggle="modal" :data-target="'#' + props.id" v-if="props.buttonType === 'text'">
      <slot name="button-txt"></slot>
    </a>

    <div class="modal fade" :id="props.id" data-backdrop="static" tabindex="-1" role="dialog" :aria-labelledby="id + '-label'" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered text-modal" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h2 class="modal-title" :id="props.id + '-label'"><slot name="header"></slot></h2>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">Sluiten <span aria-hidden="true">&times;</span></button>
          </div>

          <div class="modal-body">
            <v-row>
              <v-col>
                <slot></slot>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
