var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "", "pa-0": "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "app-dashboard-overview", attrs: { cols: "12" } },
            [
              _c(
                "v-container",
                { attrs: { fluid: "", "py-0": "" } },
                [
                  _c("header", { staticClass: "app-welcome-message" }, [
                    _c("h1", [
                      _vm._v("Welkom" + _vm._s(_setup.welcomeName) + ","),
                    ]),
                    _vm._v(" Deze applicatie beheert de pandenlijst."),
                    _c("br"),
                    _c("br"),
                  ]),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _vm._v(" Er zijn "),
                          _c(
                            "span",
                            { class: { loadingDots: !_setup.locationCount } },
                            [_vm._v(_vm._s(_setup.locationCount))]
                          ),
                          _vm._v(
                            " locaties aanwezig. Om naar het overzicht te gaan, klik "
                          ),
                          _c(
                            "router-link",
                            {
                              staticClass: "widget-link",
                              attrs: { to: { name: "Locations" } },
                            },
                            [_vm._v("hier")]
                          ),
                          _vm._v("."),
                          _c("br"),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _vm._v(" Er zijn "),
                          _c(
                            "span",
                            { class: { loadingDots: !_setup.partyCount } },
                            [_vm._v(_vm._s(_setup.partyCount))]
                          ),
                          _vm._v(
                            " partijen aanwezig. Om naar het overzicht te gaan, klik "
                          ),
                          _c(
                            "router-link",
                            {
                              staticClass: "widget-link",
                              attrs: { to: { name: "Parties" } },
                            },
                            [_vm._v("hier")]
                          ),
                          _vm._v("."),
                          _c("br"),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _vm._v(" Er zijn "),
                          _c(
                            "span",
                            {
                              class: {
                                loadingDots: !_setup.ecmDefinitionCount,
                              },
                            },
                            [_vm._v(_vm._s(_setup.ecmDefinitionCount))]
                          ),
                          _vm._v(
                            " maatregelen aanwezig. Om naar het overzicht te gaan, klik "
                          ),
                          _c(
                            "router-link",
                            {
                              staticClass: "widget-link",
                              attrs: { to: { name: "Measures" } },
                            },
                            [_vm._v("hier")]
                          ),
                          _vm._v("."),
                          _c("br"),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _vm._v(" Er zijn "),
                          _c(
                            "span",
                            { class: { loadingDots: !_setup.registerCount } },
                            [_vm._v(_vm._s(_setup.registerCount))]
                          ),
                          _vm._v(
                            " meters aanwezig. Om naar het overzicht te gaan, klik "
                          ),
                          _c(
                            "router-link",
                            {
                              staticClass: "widget-link",
                              attrs: { to: { name: "EU-Registers" } },
                            },
                            [_vm._v("hier")]
                          ),
                          _vm._v("."),
                          _c("br"),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _setup.allowWelcome
                    ? _c(
                        "v-row",
                        { staticClass: "mt-15" },
                        [
                          _c("v-col", { attrs: { cols: "auto" } }, [
                            _vm._v(" Welkom bij de pandenlijst! "),
                          ]),
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c("v-alert", { attrs: { type: "success" } }, [
                                _vm._v(
                                  "Dit bericht wordt getoond met het recht 'Welcome'!"
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }